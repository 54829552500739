import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gameplay"
    }}>{`Gameplay`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "flood"
    }}>{`Flood`}</h3>
    <p>{`After completing 30 quests, Your farm will be flooded. You cannot accept the next quest until the water is pumped away.`}</p>
    <p><img alt="mat" src={require("./public/images/flood-1.png")} /></p>
    <p>{`Cost of water pumping`}</p>
    <ul>
      <li parentName="ul">{`Normal Farm     :   350 CLC`}</li>
      <li parentName="ul">{`Great Farm      :   1500 CLC`}</li>
    </ul>
    <p><img alt="mat" src={require("./public/images/flood.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      